import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';
import { Typography } from '@data-products-and-ai/react-components';

type ToastSeverity = 'success' | 'error' | 'info';

interface ToastState {
  message: string;
  severity: ToastSeverity;
  open: boolean;
}

interface ToastContextType {
  showToast: (message: string, severity?: ToastSeverity) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toast, setToast] = useState<ToastState>({ message: '', severity: 'success', open: false });

  const showToast = useCallback((message: string, severity: ToastSeverity = 'success') => {
    setToast({ message, severity, open: true });
  }, []);

  const handleClose = () => {
    setToast((prev) => ({ ...prev, open: false }));
  };

  const getBackgroundColor = (severity: ToastSeverity) => {
    switch (severity) {
      case 'success':
        return '#007942';
      case 'error':
        return '#DA1E28';
      case 'info':
        return '#E2D000';
      default:
        return '#007942';
    }
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={toast.open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          icon={false}
          onClose={handleClose}
          sx={{
            backgroundColor: getBackgroundColor(toast.severity),
            color: 'white',
            width: '100%',
          }}
        >
          <Typography tag={"textsmall"}>{toast.message}</Typography>
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};