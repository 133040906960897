import { Button, Tooltip } from '@data-products-and-ai/react-components';

const ScrapReworkPercentage = ({
  itemId,
  itemGood,
  itemScrap,
  itemRework,
  is_open,
}: {
  itemId: string;
  itemGood: string;
  itemScrap: string;
  itemRework: string;
  is_open: boolean;
}) => {
  if (!is_open) return '';

  return (
    <>
      <Tooltip
        key={'Tooltip_Operations_' + itemId}
        color="light"
        maxWidth={500}
        title={
          itemGood != '100' && (
            <div>
              <p> Scrap: {itemScrap}%</p>
              <p> Rework: {itemRework}% </p>
            </div>
          )
        }
        disableInteractive={false}
      >
        <div style={{ display: 'inline-block' }}>
          <Button type="headless" allCaps={false}>
            {itemGood == '100' ? 'NO' : 'YES'}
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

export default ScrapReworkPercentage;
