import { useState } from 'react';

const useUploadFile = (url: string, accessToken:string) => {
  const [fileId, setFileId] = useState(null);

  const uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch(`${url}/webapi/user/v2/inputfiles`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
        body: formData,
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setFileId(data);
      return data;
    } catch (error) {
      console.error('File upload failed');
    }
  };

  return { uploadFile, fileId };
};

export default useUploadFile;