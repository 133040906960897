/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProductionOrderTree,
  Scenario,
  ScenarioSettings,
  Simulation,
  StoreState,
  TDuplicateType,
  TProcessStepOrder,
  TreeItem,
  Wip,
  WipTree,
  WipTreeElement,
} from './types';
import { produce } from 'immer';
import { generateUID, hashNumber } from '@/utils/helpers';

/* export interface StoreState {
  production_orders: any;
  products: any;
  shifts: any;
  processes: any;
  workplans: any;
} */

export const initialWip: Wip = {
  additional_equipments: [],
  operations: [],
  production_orders: [],
  products: [],
  set_additional_equipments: [],
  set_work_units: [],
  shift_calendar: [],
  shifts: [],
  shutdowns: [],
  work_plans: [],
  sites: [],
  workplan_processes: [],
  workunit_classes: [],
  additionalequipment_classes: [],
  process_types: [],
  operation_types: [],
  resources: [],
  resource_lifecycle: [],
  resource_lifecycle_status: [],
  resource_names: [],
  resource_types: [],
  resource_classes: [],
  resource_sub_classes: [],
  resource_grades: [],
  operations_resources: [],
  pause_shift: [],
  enterprises: [],
  product_classes: [],
  resource_buildings: [],
  skills: [],
  personnel: [],
  personnel_group: [],
  personnel_skills: [],
  operations_skills: [],
  personnel_by_groups: [],
  pauses: [],
  distinct_personnel_group: [],
};
export const initialWipTree: WipTree = {
  additional_equipments: { allIds: [], byId: {} },
  operations: { allIds: [], byId: {} },
  production_orders: { allIds: [], byId: {} },
  products: { allIds: [], byId: {} },
  set_additional_equipments: { allIds: [], byId: {} },
  set_work_units: { allIds: [], byId: {} },
  shift_calendar: { allIds: [], byId: {} },
  shifts: { allIds: [], byId: {} },
  shutdowns: { allIds: [], byId: {} },
  work_plans: { allIds: [], byId: {} },
  work_units: { allIds: [], byId: {} },
  sites: { allIds: [], byId: {} },
  workplan_processes: { allIds: [], byId: {} },
  workunit_classes: { allIds: [], byId: {} },
  additionalequipment_classes: { allIds: [], byId: {} },
  process_types: { allIds: [], byId: {} },
  operation_types: { allIds: [], byId: {} },
  resource_grades: { allIds: [], byId: {} },
  resource_buildings: { allIds: [], byId: {} },
  resource_classes: { allIds: [], byId: {} },
  resource_sub_classes: { allIds: [], byId: {} },
  resource_types: { allIds: [], byId: {} },
  resources: { allIds: [], byId: {} },
  operations_resources: { allIds: [], byId: {} },
  pause_shift: { allIds: [], byId: {} },
  resource_lifecycle: { allIds: [], byId: {} },
  resource_lifecycle_status: { allIds: [], byId: {} },
  skills: { allIds: [], byId: {} },
  personnel: { allIds: [], byId: {} },
  personnel_group: { allIds: [], byId: {} },
  personnel_skills: { allIds: [], byId: {} },
  operations_skills: { allIds: [], byId: {} },
  personnel_by_groups: { allIds: [], byId: {} },
  pauses: { allIds: [], byId: {} },
  distinct_personnel_group: { allIds: [], byId: {} },
};

export const initialState: StoreState = {
  WIP: initialWip,
  Simulation: {
    is_started: false,
    title: '',
    description: '',
    date_start: 0,
    date_end: 0,
    sites: [],
    scenarios: [
      {
        id: 'scenario_1',
        sequence_id: 1,
        settings: {
          title: 'Scenario_1',
          description: '',
          parameters: [
            {
              Fixed_WaitOnBatchTimeAsFixedHours: false,
              Fixed_WaitOnBatchTimeAsFixedHours_value: 8,
              Variable_WaitOnBatchTimeAsProcessTimePercentage: true,
              Variable_WaitOnBatchTimeAsProcessTimePercentage_value: 80,
              DropDown_JobsPrioritisation: 3,
              DropDown_UseOfAdditionalEquipment: true,
              DropDown_UseEachPOStartDate: true,
            },
          ],
        },
        is_selected: true,
        data: initialWipTree,
      },
    ],
  },
};
/* 
const insert = () => {
  dispatch(
    insertWIP({
      wip: WIPObject,
    }),
  );
}; */
const storeSlice = createSlice({
  name: 'store',
  initialState,

  reducers: {
    resetWIP: () => {
      return initialState;
    },
    insertWIP: (
      state: StoreState,
      action: PayloadAction<{
        wip: Wip;
      }>,
    ) => {
      const { wip } = action.payload;
      state.WIP = wip;
    },
    insertWIPTree: (
      state: StoreState,
      action: PayloadAction<{
        wipTree: WipTree;
      }>,
    ) => {
      const { wipTree } = action.payload;
      state.Simulation.scenarios[0].data = wipTree;
    },

    editSimulationTitle: (
      state: StoreState,
      action: PayloadAction<{
        title: string;
      }>,
    ) => {
      const { title } = action.payload;
      state.Simulation.title = title;
    },

    deleteSimulation: (state: StoreState) => {
      state.Simulation = initialState.Simulation;
    },

    startSimulation: (
      state: StoreState,
      action: PayloadAction<{
        simulation: Omit<Simulation, 'scenarios'>;
      }>,
    ) => {
      const { simulation } = action.payload;
      state.Simulation.is_started = simulation.is_started;
      state.Simulation.title = simulation.title;
      state.Simulation.description = simulation.description;
      state.Simulation.date_start = simulation.date_start;
      state.Simulation.date_end = simulation.date_end;
      state.Simulation.sites = simulation.sites;

      const node = state.Simulation.scenarios[0].data.production_orders;

      const removeChildren = (
        parentId: string,
        node: WipTreeElement<ProductionOrderTree>,
      ) => {
        const item = node.byId[parentId];

        if (item.children && item.children.length > 0) {
          item.children.forEach((childId: string) => {
            // Remove the child and its descendants
            removeChildren(childId, node);

            // Remove the child from allIds
            node.allIds = node.allIds.filter((id: string) => id !== childId);

            // Remove the child from byId
            delete node.byId[childId];
          });
        }
      };

      // Gather information about items to be removed
      const itemsToRemove: string[] = [];
      node.allIds.forEach((id) => {
        const item = node.byId[id];
        if (
          (item.PO_START_DATE_TIMESTAMP.value >= simulation.date_start &&
            item.PO_END_DATE_TIMESTAMP.value <= simulation.date_end &&
            item.PARENT_PRODUCTION_ORDER_ID.value === null) ||
          item.PARENT_PRODUCTION_ORDER_ID.value !== null
        ) {
          // Keep the item
        } else {
          itemsToRemove.push(id);
        }
      });

      // Remove the items and their descendants
      itemsToRemove.forEach((id) => {
        // Remove the children and their descendants
        removeChildren(id, node);
        // Remove the item from allIds
        node.allIds = node.allIds.filter((item) => item !== id);
      });

      // Remove the items from byId
      itemsToRemove.forEach((id) => {
        delete state.Simulation.scenarios[0].data.production_orders.byId[id];
      });
    },

    duplicateScenario: (
      state: StoreState,
      action: PayloadAction<{
        scenario: Scenario;
      }>,
    ) => {
      const { scenario } = action.payload;
      state.Simulation.scenarios.push({
        ...scenario,
        id: 'scenario_' + (state.Simulation.scenarios.length + 1),
        sequence_id: state.Simulation.scenarios.length + 1,
        settings: {
          ...scenario.settings,
          title: scenario.settings.title + '_copy',
        },

        is_selected: false,
      });
    },

    selectScenario: (
      state: StoreState,
      action: PayloadAction<{
        scenario: Scenario;
      }>,
    ) => {
      const { scenario } = action.payload;

      for (let i = 0; i < state.Simulation.scenarios.length; i++) {
        if (
          state.Simulation.scenarios[i].sequence_id === scenario.sequence_id
        ) {
          state.Simulation.scenarios[i].is_selected = true;
        } else {
          state.Simulation.scenarios[i].is_selected = false;
        }
      }
    },
    deleteScenario: (
      state: StoreState,
      action: PayloadAction<{
        scenario: Scenario;
      }>,
    ) => {
      const { scenario } = action.payload;

      const indexToRemove = state.Simulation.scenarios.findIndex(
        (obj) => obj.sequence_id === scenario.sequence_id,
      );
      if (indexToRemove !== -1) {
        if (state.Simulation.scenarios[indexToRemove].is_selected) {
          if (indexToRemove === 0)
            state.Simulation.scenarios[indexToRemove + 1].is_selected = true;
          else state.Simulation.scenarios[indexToRemove - 1].is_selected = true;
        }

        state.Simulation.scenarios.splice(indexToRemove, 1);

        // Update the IDs of the subsequent objects
        for (
          let i = indexToRemove;
          i < state.Simulation.scenarios.length;
          i++
        ) {
          state.Simulation.scenarios[i].sequence_id =
            state.Simulation.scenarios[i].sequence_id - 1;
        }
      }
    },

    updateScenarioSettings: (
      state,
      action: PayloadAction<{ settings: ScenarioSettings }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );

      if (selectedScenarioIndex !== -1) {
        state.Simulation.scenarios[selectedScenarioIndex].settings =
          action.payload.settings;
      }
    },
    test: (state, action: PayloadAction<{ id: string; value: TreeItem }>) => {
      const { id, value } = action.payload;

      // Use Immer's produce function to update the state
      return produce(state, (draftState) => {
        draftState.Simulation.scenarios[0].data.production_orders.byId[
          id
        ].PRODUCTION_ORDER = value;
      });
    },
    /*  disableRow: (
      state,
      action: PayloadAction<{
        id: string;
        isDisabled: boolean;
        area: keyof WipTree;
      }>,
    ) => {
      const { id, isDisabled } = action.payload;

      // Use Immer's produce function to update the state
      return produce(state, (draftState) => {
        draftState.Simulation.scenarios[0].data.production_orders.byId[
          id
        ].is_disabled = isDisabled;
      });
    }, */
    deleteRow: (
      state,
      action: PayloadAction<{
        id: string;
        area: keyof WipTree;
      }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );
      const { id, area } = action.payload;

      // Use Immer's produce function to update the state
      return produce(state, (draftState) => {
        const updateIsDisabledRecursively = (parentId: string) => {
          const parent =
            draftState.Simulation.scenarios[selectedScenarioIndex].data[area]
              .byId[parentId];
          if (parent.children && parent.children.length > 0) {
            parent.children.forEach((childId) => {
              const child =
                draftState.Simulation.scenarios[selectedScenarioIndex].data[
                  area
                ].byId[childId];
              child.is_deleted = true;
              child.is_disabled = true;
              updateIsDisabledRecursively(childId); // recursively update children
            });
          }
          // Update the parent's isDisabled property
          parent.is_deleted = true;
          parent.is_disabled = true;
        };

        // Update the initial node's isDisabled property and its children
        updateIsDisabledRecursively(id);
      });
    },

    disableRow: (
      state,
      action: PayloadAction<{
        id: string;
        isDisabled: boolean;
        area: keyof WipTree;
      }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );
      const { id, area, isDisabled } = action.payload;

      // Use Immer's produce function to update the state
      return produce(state, (draftState) => {
        const updateIsDisabledRecursively = (parentId: string) => {
          const parent =
            draftState.Simulation.scenarios[selectedScenarioIndex].data[area]
              .byId[parentId];
          if (parent.children && parent.children.length > 0) {
            parent.children.forEach((childId) => {
              const child =
                draftState.Simulation.scenarios[selectedScenarioIndex].data[
                  area
                ].byId[childId];
              child.is_disabled = isDisabled;
              updateIsDisabledRecursively(childId); // recursively update children
            });
          }
          // Update the parent's isDisabled property
          parent.is_disabled = isDisabled;
        };

        // Update the initial node's isDisabled property and its children
        updateIsDisabledRecursively(id);
      });
    },

    duplicateItem: (
      state,
      action: PayloadAction<{
        id: string;
        id_key: string;
        area: keyof WipTree;
      }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );
      const { id, id_key, area } = action.payload;
      console.log(id, id_key, area);

      return produce(state, (draftState) => {
        const uid = generateUID();
        const originalItem =
          draftState.Simulation.scenarios[selectedScenarioIndex].data[area]
            .byId[id];

        const newItem = {
          ...originalItem,
          key: uid,
          is_open: false,
          [id_key]: {
            value: uid,
            originalValue: uid,
          },
        };

        draftState.Simulation.scenarios[selectedScenarioIndex].data[area].byId[
          uid
        ] = newItem;

        draftState.Simulation.scenarios[selectedScenarioIndex].data[
          area
        ].allIds.push(uid);
      });
    },

    duplicateRow: (
      state,
      action: PayloadAction<{
        id: string;
        num: number;
        intervalStart: number;
        intervalEnd: number;
        duplicateType: TDuplicateType;
        area: keyof WipTree;
      }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );
      const { id, num, intervalEnd, intervalStart, duplicateType } =
        action.payload;

      return produce(state, (draftState) => {
        const duplicateNodeRecursively = (
          parentId: string,
          newParentId: string,
          originalId: string,
          uid: string,
          idx: number = 0,
        ) => {
          const parent =
            draftState.Simulation.scenarios[selectedScenarioIndex].data
              .production_orders.byId[parentId];

          const intervalDuration = Math.round(intervalEnd - intervalStart);
          const intervalStep = Math.round(intervalDuration / num);

          /* const newStart =
            intervalStart + (num > 1 ? idx + 1 : idx) * intervalStep;
          const newEnd =
            newStart +
            (parent.PO_END_DATE_TIMESTAMP.value -
              parent.PO_START_DATE_TIMESTAMP.value); */

          const newEnd =
            intervalStart + (num > 1 ? idx + 1 : idx) * intervalStep;
          let newStart =
            newEnd -
            (parent.PO_END_DATE_TIMESTAMP.value -
              parent.PO_START_DATE_TIMESTAMP.value);

          //Create unique PO times
          const date = new Date(newStart * 1000);
          date.setHours(8, 0, 0, 0);
          newStart = Math.floor(date.getTime() / 1000);
          const randomNumber = Math.floor(Math.random() * 3600 * 4) + 1;
          newStart += randomNumber;

          const randomStart =
            Math.random() * (intervalEnd - intervalStart) + intervalStart;
          const randomEnd =
            randomStart +
            (parent.PO_END_DATE_TIMESTAMP.value -
              parent.PO_START_DATE_TIMESTAMP.value);

          const newParent: ProductionOrderTree = {
            ...parent,
            key: newParentId,
            is_open: false,
            PRODUCTION_ORDER_ID: {
              value: newParentId,
              originalValue: newParentId,
            },
            PRODUCTION_ORDER: {
              value:
                parent.PRODUCTION_ORDER.value +
                `_${
                  (idx + 1).toString().length < 2 ? '0' + (idx + 1) : idx + 1
                }`,
              originalValue:
                parent.PRODUCTION_ORDER.value +
                `_${
                  (idx + 1).toString().length < 2 ? '0' + (idx + 1) : idx + 1
                }`,
            },
            PO_START_DATE_TIMESTAMP: {
              value: duplicateType === 'Random' ? randomStart : newStart,
              originalValue:
                duplicateType === 'Random' ? randomStart : newStart,
            },
            PO_END_DATE_TIMESTAMP: {
              value: duplicateType === 'Random' ? randomEnd : newEnd,
              originalValue: duplicateType === 'Random' ? randomEnd : newEnd,
            },

            children: [],
          };

          if (originalId !== newParentId) {
            newParent.PARENT_PRODUCTION_ORDER_ID = {
              value: hashNumber(parent.PARENT_PRODUCTION_ORDER_ID.value + uid),
              originalValue: hashNumber(
                parent.PARENT_PRODUCTION_ORDER_ID.value + uid,
              ),
            };
          } else {
            if (parent.PARENT_PRODUCTION_ORDER_ID.value) {
              draftState.Simulation.scenarios[
                selectedScenarioIndex
              ].data.production_orders.byId[
                parent.PARENT_PRODUCTION_ORDER_ID.value
              ].children.push(newParentId);
            }
          }

          if (parent.children && parent.children.length > 0) {
            parent.children.forEach((childId) => {
              const newChildId = hashNumber(childId + uid);
              newParent.children.push(newChildId);

              /*       draftState.Simulation.scenarios[
                selectedScenarioIndex
              ].data.production_orders.allIds.push(newChildId); */

              // console.log('Child added', newChildId);

              duplicateNodeRecursively(
                childId,
                newChildId,
                originalId,
                uid,
                idx,
              ); // recursively duplicate children
            });
          }

          draftState.Simulation.scenarios[
            selectedScenarioIndex
          ].data.production_orders.byId[newParentId] = newParent;

          draftState.Simulation.scenarios[
            selectedScenarioIndex
          ].data.production_orders.allIds.push(newParentId);

          // console.log('Main added', newParentId);
          //  console.log('Main added by id', newParent);
        };

        // Generate a new ID for the duplicated root node
        for (let i = 0; i < num; i++) {
          const uid = generateUID();
          const newRootId = hashNumber(id + uid);
          duplicateNodeRecursively(id, newRootId, newRootId, uid, i);
        }

        /*        const newRootId = id + '_Carlos';
        duplicateNodeRecursively(id, newRootId);*/
      });
    },

    changeExpanded: (
      state,
      action: PayloadAction<{
        id: string;
        isExpanded: boolean;
        area: keyof WipTree;
      }>,
    ) => {
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );
      const { id, isExpanded, area } = action.payload;

      console.log(area, id, isExpanded);

      // Use Immer's produce function to update the state
      return produce(state, (draftState) => {
        draftState.Simulation.scenarios[selectedScenarioIndex].data[area].byId[
          id
        ].is_open = !isExpanded;
      });
    },

    insert: (
      state: StoreState,
      action: PayloadAction<{
        id: string;
        area: keyof WipTree;
        payload: any;
      }>,
    ) => {
      console.log(state, action);
      /*       const { key, value, section } = action.payload;
      state.WIP[section][key] = value; */
    },

    update: <T>(
      state: StoreState,
      action: PayloadAction<{
        id?: string;
        area: keyof WipTree;
        content: T;
      }>,
    ) => {
      const { id, area, content } = action.payload;

      console.log(action.payload);
      if (!id) return;
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );

      return produce(state, (draftState) => {
        draftState.Simulation.scenarios[selectedScenarioIndex].data[area].byId[
          id
        ] = {
          ...draftState.Simulation.scenarios[selectedScenarioIndex].data[area]
            .byId[id],
          ...content,
        };
      });
    },
    updateProcessSteps: (
      state: StoreState,
      action: PayloadAction<{
        id?: string;
        content: TProcessStepOrder[];
      }>,
    ) => {
      const { id, content } = action.payload;

      if (!id && content && content.length < 1) return;
      const selectedScenarioIndex = state.Simulation.scenarios.findIndex(
        (scenario) => scenario.is_selected,
      );

      return produce(state, (draftState) => {
        let step = 0;
        for (const item of content) {
          console.log(item);
          draftState.Simulation.scenarios[
            selectedScenarioIndex
          ].data.operations.byId[item.id].STEP.value = (++step).toString();
        }
      });

      /*  return produce(state, (draftState) => {
        draftState.Simulation.scenarios[selectedScenarioIndex].data[area].byId[
          id
        ] = {
          ...draftState.Simulation.scenarios[selectedScenarioIndex].data[area]
            .byId[id],
          ...content,
        };
      }); */
    },
  },
});

export const {
  resetWIP,
  insert,
  deleteRow,
  disableRow,
  duplicateItem,
  duplicateRow,
  update,
  updateProcessSteps,
  insertWIP,
  insertWIPTree,
  editSimulationTitle,
  deleteSimulation,
  startSimulation,
  duplicateScenario,
  updateScenarioSettings,
  selectScenario,
  deleteScenario,
  test,
  changeExpanded,
} = storeSlice.actions;

export default storeSlice.reducer;
