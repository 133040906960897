import {
  DistinctPersonnelGroup,
  Personnel,
  PersonnelByGroups,
  PersonnelSkills,
  SiteTree,
  Skills,
  TreeItem,
  WipItemParams,
  WipTree,
  WipTreeElement,
} from '@/store/types';
import { ReactNode } from 'react';

interface ProductTreeRender extends WipItemParams {
  NAME: TreeItem;
  [key: string]: TreeItem | string | number | ReactNode; // Allow dynamic keys for skills
}

type ProductTreeRenderGeneric = {
  [K in keyof ProductTreeRender]: string | number | ReactNode;
};

export type TProductStructure = {
  id: string;
  data: ProductTreeRenderGeneric;
  children: TProductStructure[];
  isClickable?: boolean;
  isExpanded?: boolean;
  isHidden?: boolean;
  isDisabled?: boolean;
  isSelectable?: boolean;
  area: keyof WipTree;
};

function findAndPushItem(
  obj: TProductStructure[],
  idToFind: string,
  newItem: TProductStructure,
): boolean {
  for (const item of obj) {
    if (item.id === idToFind) {
      if (!item.children) {
        item.children = []; // Ensure children array exists before pushing
      }
      item.children.push(newItem);

      item.children.sort((a, b) => {
        const stepA = (a.data.NAME as string) || ''; // Use empty string as default value
        const stepB = (b.data.NAME as string) || ''; // Use empty string as default value

        return stepA.localeCompare(stepB);
      });
      return true; // Return true to stop further recursion once the item is found
    } else if (item.children) {
      if (findAndPushItem(item.children, idToFind, newItem)) {
        return true; // If the item is found in the child, stop further recursion
      }
    }
  }
  return false; // Return false if the item is not found in the current objects or its children
}

export const generatePersonnelTree = (
  sitesObject: WipTreeElement<SiteTree>,
  distinctPersonnelGroupsObject: WipTreeElement<DistinctPersonnelGroup>,
  personnelByGroupsObject: WipTreeElement<PersonnelByGroups>,
  personnelObject: WipTreeElement<Personnel>,
  skillsObject: WipTreeElement<Skills>,
  skillsPersonnelObject: WipTreeElement<PersonnelSkills>,
): TProductStructure[] => {
  const objectWithChildren: TProductStructure[] = [];
  const itemMap: { [itemId: string]: TProductStructure } = {};
  const siteIds: string[] = [];

  //Determine the maximum number of skills
  let maxSkills = 0;
  personnelByGroupsObject.allIds.forEach((itemId: string) => {
    const byGroups = personnelByGroupsObject.byId[itemId];
    const personId = byGroups.PERSON_ID.value;

    if (personnelObject.byId[personId]) {
      const skillCount = skillsPersonnelObject.allIds.filter(
        (skillId: string) =>
          skillsPersonnelObject.byId[skillId].PERSON_ID.value === personId,
      ).length;
      maxSkills = Math.max(maxSkills, skillCount);
    }
  });

  sitesObject.allIds.forEach((itemId: string) => {
    const item = sitesObject.byId[itemId];
    const newItem: TProductStructure = {
      id: item.SITE_ID.value,
      area: 'sites',
      isExpanded: item.is_open,
      isDisabled: item.is_disabled,
      data: {
        key: item.SITE_ID.value,
        title: item.SITE.value,
        is_deleted: item.is_deleted,
        is_wip: item.is_wip,
        is_disabled: item.is_disabled,
        is_clicked: item.is_clicked,
        is_selectable: item.is_selectable,
        is_selected: item.is_selected,
        is_hidden: item.is_hidden,
        is_match: item.is_match,
        is_open: item.is_open,
        parent: '',
        NAME: item.SITE.value,
        ...Array.from({ length: maxSkills }, (_, i) => ({
          [`SKILL_${i + 1}`]: '',
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        children: [],
      },
      children: [],
    };

    objectWithChildren.push(newItem);
    siteIds.push(item.SITE_ID.originalValue);
    itemMap[itemId] = newItem;
  });

  distinctPersonnelGroupsObject.allIds.forEach((itemId: string) => {
    const item = distinctPersonnelGroupsObject.byId[itemId];
    const newItem: TProductStructure = {
      id: item.PERSONNEL_GROUP_ID.value,
      area: 'distinct_personnel_group',
      isExpanded: item.is_open,
      isDisabled: item.is_disabled,
      data: {
        key: item.PERSONNEL_GROUP_ID.value,
        title: item.PERSONNEL_GROUP.value,
        is_deleted: item.is_deleted,
        is_wip: item.is_wip,
        is_disabled: item.is_disabled,
        is_clicked: item.is_clicked,
        is_selectable: item.is_selectable,
        is_selected: item.is_selected,
        is_hidden: item.is_hidden,
        is_match: item.is_match,
        is_open: item.is_open,
        parent: '',
        NAME: item.PERSONNEL_GROUP.value,
        ...Array.from({ length: maxSkills }, (_, i) => ({
          [`SKILL_${i + 1}`]: '',
        })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        children: [],
      },
      children: [],
    };

    siteIds.forEach((siteId: string) => {
      findAndPushItem(objectWithChildren, siteId, newItem);
      itemMap[itemId] = newItem;
    });
  });

  personnelByGroupsObject.allIds.forEach((itemId: string) => {
    const byGroups = personnelByGroupsObject.byId[itemId];
    const personId = byGroups.PERSON_ID.value;

    if (personnelObject.byId[personId]) {
      const personnelItem = personnelObject.byId[personId];

      const skills = skillsPersonnelObject.allIds
        .filter(
          (skillId: string) =>
            skillsPersonnelObject.byId[skillId].PERSON_ID.value === personId,
        )
        .map((skillId: string) => {
          const skill =
            skillsObject.byId[
              skillsPersonnelObject.byId[skillId].SKILL_ID.value
            ];
          return skill ? skill.SKILL.value : '';
        });

      console.log(`Skills for ${personnelItem.PERSON_NAME.value}:`, skills);

      const newItem: TProductStructure = {
        id: personnelItem.PERSON_ID.value,
        area: 'personnel',
        isExpanded: personnelItem.is_open,
        isDisabled: personnelItem.is_disabled,
        data: {
          key: personnelItem.PERSON_ID.value,
          title: personnelItem.PERSON_NAME.value,
          is_deleted: personnelItem.is_deleted,
          is_wip: personnelItem.is_wip,
          is_disabled: personnelItem.is_disabled,
          is_clicked: personnelItem.is_clicked,
          is_selectable: personnelItem.is_selectable,
          is_selected: personnelItem.is_selected,
          is_hidden: personnelItem.is_hidden,
          is_match: personnelItem.is_match,
          is_open: true,
          parent: '',
          NAME: personnelItem.PERSON_NAME.value,
          ...Array.from({ length: maxSkills }, (_, i) => ({
            [`SKILL_${i + 1}`]: skills[i] || '',
          })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          children: [],
        },
        children: [],
      };

      findAndPushItem(
        objectWithChildren,
        byGroups.PERSONNEL_GROUP_ID.value,
        newItem,
      );
    }
  });

  return objectWithChildren;
};
