import { AppDispatch, RootState } from '@/store';
import { changeExpanded } from '@/store/storeSlice';
import { TSimulationMenuOptions, WipTree } from '@/store/types';
import { Input, Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
  TableHeaderAlignType,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TAnchorEl } from '@data-products-and-ai/react-components/lib/components/Layout/Menu/Menu';
import { useContext, useState, useEffect, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContextMenu } from '../ContextMenu';
import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import {
  generatePersonnelTree,
  TProductStructure,
} from '@/pages/Simulation/TableView/Personnel/generatePersonnelTree';

const Personnel = () => {
  const { setSimulationParams } = useContext(SimulationCtx);
  const [anchorEl, setAnchorEl] = useState<TAnchorEl | null>(null);
  const [selectedRow, setSelectedRow] = useState<TableRowData | null>(null);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected),
  );
  const [objectWithChildren, setObjectWithChildren] = useState<
    TProductStructure[]
  >([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [tableHeaders, setTableHeaders] = useState<TableHeaderType[]>([
    {
      title: 'Name',
      align: 'left' as TableHeaderAlignType,
      columnKey: 'NAME',
    },
  ]);

  useEffect(() => {
    if (!selectedScenario) return;
    const generatedTree = generatePersonnelTree(
      selectedScenario.data.sites,
      selectedScenario.data.distinct_personnel_group,
      selectedScenario.data.personnel_by_groups,
      selectedScenario.data.personnel,
      selectedScenario.data.skills,
      selectedScenario.data.personnel_skills,
    );

    setObjectWithChildren(generatedTree);
    if (generatedTree.length > 0) {
      const countSkills = (items: TProductStructure[]): number => {
        let maxSkills = 0;
        for (const item of items) {
          const skillCount = Object.keys(item.data).filter((key) =>
            key.startsWith('SKILL_'),
          ).length;
          maxSkills = Math.max(maxSkills, skillCount);
          if (item.children && item.children.length > 0) {
            maxSkills = Math.max(maxSkills, countSkills(item.children));
          }
        }
        return maxSkills;
      };

      const maxSkills = countSkills(generatedTree);

      const headers: TableHeaderType[] = [
        {
          title: 'Name',
          align: 'left' as TableHeaderAlignType,
          columnKey: 'NAME',
        },
        ...Array.from({ length: maxSkills }, (_, i) => ({
          title: `Skill ${i + 1}`,
          align: 'left' as TableHeaderAlignType,
          columnKey: `SKILL_${i + 1}`,
        })),
      ];

      setTableHeaders(headers);
    }
  }, [selectedScenario]);

  if (!selectedScenario) return <>No Scenario</>;

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterQuery(e.target.value);
  };

  const handleClick = (value: TableRowData) => {
    if (!value.children) return;
    if (value.children.length <= 0) return;

    dispatch(
      changeExpanded({
        id: value.id,
        isExpanded: value.isExpanded ?? false,
        area: value.area as keyof WipTree,
      }),
    );
  };

  const handleDoubleClick = (value: TableRowData) => {
    const area = value.area as TSimulationMenuOptions;
    if (area !== 'resource_lifecycle') return;

    setSimulationParams((prevState) => ({
      ...prevState,
      drawerOpen: true,
      formItem: value.id,
      formArea: area,
    }));
  };

  const handleMenuRight = (value: TableRowData, event?: React.MouseEvent) => {
    const area = value.area as keyof WipTree;

    if (area !== 'resource_lifecycle') return;
    event &&
      setAnchorEl({
        left: event.clientX,
        top: event.clientY,
      });

    setSelectedRow(value);
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <Input id="Filter" onChange={handleInput} placeholder="Filter" />
      </div>

      <ContextMenu
        selectedRow={selectedRow}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      />
      <Table
        headers={tableHeaders}
        width={'100%'}
        isSelectable={false}
        hasFilter={true}
        filterQuery={filterQuery}
        rows={objectWithChildren}
        pointerCursor={true}
        typographyTag="textsmall"
        onRowClick={(value) => handleClick(value)}
        onRowDoubleClick={(value) => handleDoubleClick(value)}
        onRowRightClick={(value, event) => handleMenuRight(value, event)}
        stickyHeader={true}
        headerVerticalAlign="bottom"
      />
    </>
  );
};

export default Personnel;
