import { SimulationCtx } from '@/contexts/Simulation/SimulationCtx';
import { AppDispatch, RootState } from '@/store';
import { update } from '@/store/storeSlice';
import { TreeItem } from '@/store/types';
import {
  Button,
  Input,
  Label,
  Stack,
} from '@data-products-and-ai/react-components';
import { produce } from 'immer';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './FormOperations.scss';

export type TFormOperations = {
  OPERATION: TreeItem;
  OPERATION_TYPE_ID: TreeItem;
  STEP: TreeItem<number>;
  OPERATION_BUSINESS: TreeItem;
};

const FormOperations = () => {
  const { simulationParams, setSimulationParams } = useContext(SimulationCtx);
  const dispatch: AppDispatch = useDispatch();
  const selectedScenario = useSelector((state: RootState) =>
    state.store.Simulation.scenarios.find((item) => item.is_selected === true),
  );

  const WipArea = 'operations';

  const InitialFormProducts: TFormOperations = {
    OPERATION: { value: '', originalValue: '' },
    OPERATION_BUSINESS: { value: '', originalValue: '' },
    OPERATION_TYPE_ID: { value: '', originalValue: '' },
    STEP: { value: 0, originalValue: 0 },
  };

  const [form, setForm] = useState(InitialFormProducts);

  const handleInputChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { id, value } = event.target as HTMLInputElement & {
      id: string;
      value: string;
      type: string;
      checked: boolean;
    };
    const updateForm = (id: keyof TFormOperations, newValue: unknown) => {
      let v = newValue;
      if (typeof form[id] === 'number') {
        v = parseInt(v as string);
      }
      setForm((prevForm) => ({
        ...prevForm,
        [id]: {
          ...prevForm[id],
          value: v,
        },
      }));
    };

    updateForm(id as keyof TFormOperations, value);
  };

  const handleCloseDrawer = () => {
    setSimulationParams(
      produce((draft) => {
        draft.formItem = undefined;
        draft.formArea = undefined;
        draft.drawerOpen = false;
      }),
    );

    setForm(InitialFormProducts);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(
      update({
        id: simulationParams.formItem,
        area: WipArea,
        content: form,
      }),
    );

    handleCloseDrawer();
  };

  const selectedItem =
    simulationParams.formItem && selectedScenario
      ? selectedScenario.data[WipArea].byId[simulationParams.formItem]
      : null;

  useEffect(() => {
    if (!selectedItem) return;

    const formToChange: TFormOperations = {
      OPERATION: {
        value: selectedItem.OPERATION.value,
        originalValue: selectedItem.OPERATION.originalValue,
      },
      OPERATION_BUSINESS: {
        value: selectedItem.OPERATION_BUSINESS.value,
        originalValue: selectedItem.OPERATION_BUSINESS.originalValue,
      },
      OPERATION_TYPE_ID: {
        value: selectedItem.OPERATION_TYPE_ID.value,
        originalValue: selectedItem.OPERATION_TYPE_ID.originalValue,
      },

      STEP: {
        value: parseInt(selectedItem.STEP.value),
        originalValue: parseInt(selectedItem.STEP.originalValue),
      },
    };
    setForm(formToChange);
  }, [selectedItem]);

  if (!selectedItem || !selectedScenario) return;

  type TSelect = {
    id: string;
    name: string;
  };

  const sites: TSelect[] = [];
  selectedScenario.data.sites.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.sites.byId[itemId];

    const newItem = {
      id: item.SITE_ID.originalValue,
      name: item.SITE.value,
    };

    sites.push(newItem);
  });
  const workplans: TSelect[] = [];
  selectedScenario.data.work_plans.allIds.forEach((itemId: string) => {
    const item = selectedScenario.data.work_plans.byId[itemId];

    const newItem = {
      id: item.WORK_PLAN_ID.originalValue,
      name: item.WORK_PLAN.value,
    };

    workplans.push(newItem);
  });

  const formMargin = 30;

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: 20,
          paddingBottom: 100,
        }}
      >
        <div
          style={{
            marginTop: formMargin / 2,
          }}
        ></div>
        <Label tag="textsmall_strong">Operation</Label>
        <Input
          size="small"
          id="OPERATION"
          defaultValue={form.OPERATION.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        <Label marginTop={formMargin} tag="textsmall_strong">
          Operation ID
        </Label>
        <Input
          size="small"
          id="OPERATION_BUSINESS"
          defaultValue={form.OPERATION_BUSINESS.value}
          onChange={(event) => handleInputChange(event)}
        ></Input>

        {/*    {<pre>{JSON.stringify(form, null, 2)}</pre>} */}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            marginTop: 30,
            backgroundColor: 'white',
            paddingBottom: 20,
            paddingTop: 20,
            width: `calc(100% - 40px)`,
            borderTop: 'solid 1px #ededed',
          }}
        >
          <Stack direction="row" distribute="space-around">
            <Button
              buttonType="button"
              type="primaryOutline"
              width="200px"
              onClick={handleCloseDrawer}
            >
              Cancel
            </Button>
            <Button buttonType="submit" type="primaryNoShadow" width="200px">
              Update
            </Button>
          </Stack>
        </div>
      </div>
    </form>
  );
};

export default FormOperations;
