import { useState, useEffect } from 'react';
import { Method } from 'axios';
import ApiCall from '@/services/ApiConnector/ApiConnector';

const useOAuth = (baseUrl: string, clientId: string, clientSecret: string) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAccessToken = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await ApiCall(
          'POST' as Method,
          '/webapi/oauth2/token',
          { baseUrl },
          {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`
        );

        setAccessToken(response.data.access_token);
        console.log(response.data.access_token);

      } catch (err) {
        setError('Failed to fetch access token');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAccessToken();
  }, [baseUrl, clientId, clientSecret]);

  return { accessToken, error, loading };
};

export default useOAuth;
