import axios, { AxiosRequestConfig, Method } from 'axios';

interface ApiConfig {
  baseUrl: string;
  accessToken?: string;
}

const ApiCall = async (
  method: Method,
  endpoint: string,
  { baseUrl, accessToken }: ApiConfig,
  headers: AxiosRequestConfig['headers'],
  payload?: unknown,
  responseType: AxiosRequestConfig['responseType'] = 'json',
) => {
  const url = baseUrl + endpoint;

  if (accessToken) {
    headers = {
      ...headers,
      Authorization: accessToken,
    };
  }

  const config: AxiosRequestConfig = {
    method,
    url,
    headers,
    data: payload,
    responseType,
  };

  return await axios(config);
};

export default ApiCall;
